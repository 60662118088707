import(/* webpackMode: "eager" */ "/app/app/_assets/illustrations/dotnet-resume.svg");
;
import(/* webpackMode: "eager" */ "/app/app/_assets/illustrations/footer.svg");
;
import(/* webpackMode: "eager" */ "/app/app/_assets/illustrations/fullstack-resume.svg");
;
import(/* webpackMode: "eager" */ "/app/app/_assets/illustrations/home-decor.svg");
;
import(/* webpackMode: "eager" */ "/app/app/_assets/illustrations/its_me.webp");
;
import(/* webpackMode: "eager" */ "/app/app/_assets/illustrations/me_kvant.webp");
;
import(/* webpackMode: "eager" */ "/app/app/_assets/illustrations/next-logo.svg");
;
import(/* webpackMode: "eager" */ "/app/app/_assets/illustrations/not-found.svg");
;
import(/* webpackMode: "eager" */ "/app/app/_assets/illustrations/pet-projects.svg");
;
import(/* webpackMode: "eager" */ "/app/app/_assets/illustrations/react-resume.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/_components/CookieBanner.tsx");
;
import(/* webpackMode: "eager" */ "/app/app/_components/Footer.module.scss");
;
import(/* webpackMode: "eager" */ "/app/app/_components/Button.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/_components/Sidemenu.tsx");
;
import(/* webpackMode: "eager" */ "/app/app/_data/TitleLogo.module.scss");
;
import(/* webpackMode: "eager" */ "/app/app/_components/Header.module.scss");
;
import(/* webpackMode: "eager" */ "/app/app/_components/NavigationLinks.module.scss");
;
import(/* webpackMode: "eager" */ "/app/app/_components/SocialLinks.module.scss");
;
import(/* webpackMode: "eager" */ "/app/app/globals.scss");
;
import(/* webpackMode: "eager" */ "/app/app/icon.svg");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"app/fonts.ts\",\"import\":\"Aldrich\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":\"400\",\"variable\":\"--font-alt\"}],\"variableName\":\"AldrichFont\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"app/fonts.ts\",\"import\":\"Ubuntu_Mono\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":\"400\",\"variable\":\"--font-mono\"}],\"variableName\":\"UbuntuMonoFont\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"app/fonts.ts\",\"import\":\"Ubuntu\",\"arguments\":[{\"weight\":[\"400\",\"700\"],\"subsets\":[\"latin\"],\"variable\":\"--font-base\"}],\"variableName\":\"UbuntuFont\"}");
